import React, { useEffect, useState } from 'react';
import AnimateHeight from "react-animate-height";
import { useLocation } from "@gatsbyjs/reach-router"
import { FormattedMessage, useIntl } from 'react-intl';

import { openRnDPositions } from '../../../../constants/constants';

import './R&DPositionsSection.scss';
import { defaultFormattedMessageValues } from '../../../../constants/general';
import { createId } from '../../../../services/utils';

const RnDPositionsSection = () => {
    const [openedPositions, setOpenedPositions] = useState(new Array(openRnDPositions.length).fill(false));
    const location = useLocation();
    const [preventHrefLinking, setPreventHrefLinking] = useState(false);

    const handleOpenClick = (i) => {
        openedPositions[i] = !openedPositions[i];
        setOpenedPositions([...openedPositions]);
    }

    const { messages } = useIntl();

    useEffect(() => {
        if (location.hash && !preventHrefLinking) {
            const index = openRnDPositions.findIndex(a => `#${createId(a.name)}` === location.hash);
            if (index !== -1) {
                const positions = [...openedPositions];
                if (!positions[index]) {
                    positions[index] = true;
                    setOpenedPositions(positions);
                }
            }
            setPreventHrefLinking(true);
        }
    }, [location.hash]);

    return (
        <>
            <div className="rnd-positions-section">
                <div className="rnd-position-lower-section">
                    <div className="rnd-position-search">
                        {openRnDPositions.map(({name, tags, desc, info}, i) => (
                            <div className="rnd-single-position-wrapper" key={name}>
                                <a id={createId(name)} href={`#${createId(name)}`} className={`position ${openedPositions[i] && 'opened'}`} onClick={() => handleOpenClick(i)}>
                                    <div className="position-titles">
                                        <h3 className="heading-2">{name}</h3>
                                    </div>
                                    <div className="tags">
                                        {tags.map((tag, i) => (
                                            <div className="inline-lead position-tag" key={`${i}${tag}`}>
                                                {tag}
                                            </div>
                                        ))}
                                    </div>
                                    <div
                                        className={`caret ${openedPositions[i] && 'opened'}`}
                                    >
                                        <img
                                            className="caret-image"
                                            src="/caret.svg"
                                            alt="caret"
                                            width={55}
                                            height={27.5}
                                        />
                                    </div>
                                </a>
                                <AnimateHeight duration={300} height={openedPositions[i] ? 'auto' : 0}>
                                    <div className={`position-description`} style={{ '--items': Object.entries(info).length }}>
                                        {Object.entries(info).map(([key, data], i) => (
                                          <>
                                            {!data.isList && (
                                              <div className="position-description-header">
                                                <h4 className="heading-2">
                                                  <FormattedMessage id={data.title} values={defaultFormattedMessageValues} />
                                                </h4>
                                                <p className="body-lead">
                                                  <FormattedMessage id={data.description} values={defaultFormattedMessageValues} />
                                                </p>
                                              </div>
                                            )}

                                            {data.isList && (
                                              <>
                                                {data.title && data.title !== '' && (
                                                  <ul key={`${i}-${key}`}>
                                                    <h4 className="heading-3 position-description-title">
                                                      <FormattedMessage id={data.title} />
                                                    </h4>
                                                    <div className="position-description-columns">
                                                      {data?.points?.map((item, i) => (
                                                        <>
                                                          {messages[item] && messages[item] !== '' && (
                                                            <li className="body-regular position-description-item" key={`${i}-${item}`}>
                                                              <FormattedMessage id={item} values={defaultFormattedMessageValues} />
                                                            </li>
                                                          )}
                                                        </>
                                                      ))}
                                                    </div>
                                                  </ul>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ))}
                                        <p className="position-cta body-lead">
                                            <FormattedMessage id="r&d_after_job_posting_cta" />
                                        </p>
                                    </div>
                                </AnimateHeight>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default RnDPositionsSection;