import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from "react-intl";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';

import { openRnDPositions, rndSelectionSteps } from '../../../constants/constants';

import RnDPositionsSection from './R&DPositionsSection/R&DPositionsSection';
import Arrow from '../../Arrow/Arrow';

import './R&DSection.scss';
import { defaultFormattedMessageValues } from '../../../constants/general';
import { createId } from '../../../services/utils';
import { useWindowResize } from '../../../services/hooks';

const sliderSettings = {
  slidesToShow: 3,
  focusOnSelect: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
  arrows: true,
  autoplay: false,
  centerMode: false,
  cssEase: 'ease-in-out',
  swipeToSlide: true,
  adaptiveHeight: true,
  nextArrow: <Arrow isRight />,
  prevArrow: <Arrow /> ,
};

const portfolio = [
  {
    key: 'cryptage',
    link: 'https://cryptage.co/',
    tags: ['Gaming'],
    year: 2017,
  },
  {
    key: 'cryptographics',
    link: 'https://cryptographics.app/',
    tags: ['NFT'],
    year: 2018,
  },
  {
    key: 'defisaver',
    link: 'https://defisaver.com/',
    tags: ['DeFi'],
    year: 2019,
  },
  {
    key: 'defiexplore',
    link: 'https://defiexplore.com/',
    tags: ['DeFi'],
    year: 2020,
  },
  {
    key: 'ethsaver',
    link: 'https://ethsaver.com/',
    tags: ['DeFi'],
    year: 2023,
  },
  {
    key: 'resistance_protocol',
    link: 'https://github.com/DecenterApps/ResistanceProtocol',
    tags: ['DeFi'],
    year: 2022,
  },
  {
    key: 'ratex',
    link: 'https://github.com/DecenterApps/RateX',
    tags: ['DeFi'],
    year: 2023,
  },
  {
    key: 'gas_extension',
    link: 'https://chromewebstore.google.com/detail/defi-saver-gas-prices-ext/afgfdkloegmghldbalmenklokhlifphe',
    tags: ['NFT', 'DeFi'],
    year: 2022,
  },
  {
    key: 'dfsstats',
    link: 'https://stats.defisaver.com/',
    tags: ['DeFi'],
    year: 2022,
  },
  // {
  //   key: 'fungee',
  //   link: '',
  //   tags: ['NFT', 'DeFi'],
  //   year: 'TBD',
  // },
];

const RDSection = () => {
  const images = useStaticQuery(graphql`
    query {
      ctaLookout: file(relativePath: { eq: "r&d/cta-lookout.png" }) {
        childImageSharp {
           gatsbyImageData(
              quality: 100
              height: 480
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      howExperiment: file(relativePath: { eq: "r&d/how-experiment.png" }) {
        childImageSharp {
          gatsbyImageData(
              quality: 100
              height: 480
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      howResearch: file(relativePath: { eq: "r&d/how-research.png" }) {
        childImageSharp {
           gatsbyImageData(
              quality: 100
              height: 480
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      howSharing: file(relativePath: { eq: "r&d/how-sharing.png" }) {
        childImageSharp {
           gatsbyImageData(
              quality: 100
              height: 480
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      howFreedom: file(relativePath: { eq: "r&d/how-freedom.png" }) {
        childImageSharp {
           gatsbyImageData(
              quality: 100
              height: 480
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      cryptage: file(relativePath: { eq: "r&d/portfolio/img-cryptage.png" }) {
        childImageSharp {
           gatsbyImageData(
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      cryptographics: file(relativePath: { eq: "r&d/portfolio/img-cryptographics.png" }) {
        childImageSharp {
           gatsbyImageData(
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      defiexplore: file(relativePath: { eq: "r&d/portfolio/img-defiexplore.png" }) {
        childImageSharp {
           gatsbyImageData(
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      defisaver: file(relativePath: { eq: "r&d/portfolio/img-defisaver.png" }) {
        childImageSharp {
           gatsbyImageData(
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      dfsstats: file(relativePath: { eq: "r&d/portfolio/img-dfsstats.png" }) {
        childImageSharp {
           gatsbyImageData(
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      ethsaver: file(relativePath: { eq: "r&d/portfolio/img-ethsaver.png" }) {
        childImageSharp {
           gatsbyImageData(
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      fungee: file(relativePath: { eq: "r&d/portfolio/img-fungee.png" }) {
        childImageSharp {
           gatsbyImageData(
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      resistance_protocol: file(relativePath: { eq: "r&d/portfolio/img-noi.png" }) {
        childImageSharp {
           gatsbyImageData(
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      ratex: file(relativePath: { eq: "r&d/portfolio/img-ratex.png" }) {
        childImageSharp {
           gatsbyImageData(
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      gas_extension: file(relativePath: { eq: "r&d/portfolio/img-gas-extension.png" }) {
        childImageSharp {
           gatsbyImageData(
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
`);

  const [slidesToShow, setSlidesToShow] = useState(4);

  const { windowWidth } = useWindowResize();

  useEffect(() => {
    let _slidesToShow =  4;

    if (windowWidth <= 1300) _slidesToShow = 3;
    if (windowWidth <= 890) _slidesToShow = 2;
    if (windowWidth <= 500) _slidesToShow = 1;

    setSlidesToShow(_slidesToShow);
  }, [windowWidth]);

  const sliderSettings = useMemo(
    () => ({
      slidesToShow,
      focusOnSelect: false,
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      arrows: true,
      autoplay: false,
      centerMode: false,
      cssEase: 'ease-in-out',
      swipeToSlide: true,
      adaptiveHeight: true,
      nextArrow: <Arrow isRight />,
      prevArrow: <Arrow /> ,
    }),
    [slidesToShow, windowWidth],
  );

  return (
    <div className="overflow-wrapper">
      <div className="rnd-section-wrapper padding content center">
        <section className="hero">
          <h1 className="heading-1 page-title">
            <FormattedMessage id='r&d_title' values={defaultFormattedMessageValues}/>
          </h1>

          <div className="info">
            <div className="info-section">
              <h2 className="heading-2"><FormattedMessage id='r&d_hero_info_1_title'/></h2>
              <p className="body-lead"><FormattedMessage id='r&d_hero_info_1_description'/></p>
            </div>
            <div className="info-section">
              <h2 className="heading-2"><FormattedMessage id='r&d_hero_info_2_title'/></h2>
              <p className="body-lead"><FormattedMessage id='r&d_hero_info_2_description'/></p>
            </div>
          </div>
        </section>

        <section className="about">
          <h2 className="heading-1"><FormattedMessage id='r&d_about_title'/></h2>

          <div className="info-item">
            <GatsbyImage className={"info-item-image"} image={getImage(images.howResearch.childImageSharp.gatsbyImageData)} imgStyle={{ objectFit: 'contain' }} />
            <div className="info-item-content">
              <h3 className="heading-2"><FormattedMessage id='r&d_about_item_1_title'/></h3>
              <p className="body-lead"><FormattedMessage id='r&d_about_item_1_description'/></p>
            </div>
          </div>

          <div className="info-item reverse">
            <div className="info-item-content">
              <h3 className="heading-2"><FormattedMessage id='r&d_about_item_2_title'/></h3>
              <p className="body-lead"><FormattedMessage id='r&d_about_item_2_description'/></p>
            </div>
            <GatsbyImage className={"info-item-image"} image={images.howExperiment.childImageSharp.gatsbyImageData} imgStyle={{ objectFit: 'contain' }} />
          </div>

          <div className="info-item">
            <GatsbyImage className={"info-item-image"} image={images.howSharing.childImageSharp.gatsbyImageData} imgStyle={{ objectFit: 'contain' }} />
            <div className="info-item-content">
              <h3 className="heading-2"><FormattedMessage id='r&d_about_item_3_title'/></h3>
              <p className="body-lead"><FormattedMessage id='r&d_about_item_3_description'/></p>
            </div>
          </div>

          <div className="info-item reverse">
            <div className="info-item-content">
              <h3 className="heading-2"><FormattedMessage id='r&d_about_item_4_title'/></h3>
              <p className="body-lead"><FormattedMessage id='r&d_about_item_4_description'/></p>
            </div>
            <GatsbyImage className={"info-item-image"} image={images.howFreedom.childImageSharp.gatsbyImageData} imgStyle={{ objectFit: 'contain' }} />
          </div>
        </section>

        <section className="portfolio">
          <div className="portfolio-header">
            <h2 className="heading-1"><FormattedMessage id='projects_portfolio_title'/></h2>
          </div>

          <Slider {...sliderSettings} className="portfolio-slider">
            {portfolio.map((item) => (
              <div>
                <div className="portfolio-slider-item">
                  <GatsbyImage className="portfolio-slider-image" image={images[item.key].childImageSharp.gatsbyImageData} />
                  <div className="portfolio-slider-content">
                    <span className="portfolio-slider-year">{item.year}</span>
                    <div className="portfolio-slider-header">
                      <h4 className="heading-6"><FormattedMessage id={`projects_portfolio_${item.key}_title`}/></h4>
                      <span className="portfolio-slider-tags">
                        {item.tags.map((tag) => (<span className="portfolio-slider-tag">{tag}</span>))}
                      </span>
                    </div>
                    <p className="body-small"><FormattedMessage id={`projects_portfolio_${item.key}_description`}/></p>
                    <div className="portfolio-slider-more">
                      {item.link && (
                        <a className="portfolio-slider-link" href={item.link} target="_blank" rel="noopener noreferrer">
                          <FormattedMessage id="website"/> →
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </section>

        <section className="cta">
          <h3 className="heading-1"><FormattedMessage id='r&d_cta_title'/></h3>
          <div className="info-item">
            <GatsbyImage className={"info-item-image"} image={getImage(images.ctaLookout)} imgStyle={{ objectFit: 'contain' }} />
            <div className="info-item-content">
              <p className="body-lead"><FormattedMessage id='r&d_cta_description' values={defaultFormattedMessageValues}/></p>
            </div>
          </div>
        </section>

        <section className="positions">
          <h2 className="heading-1"><FormattedMessage id='r&d_positions_title'/></h2>

          <RnDPositionsSection />
        </section>

        <section className="process">
          <div className="selection-process">
            <h3 className="heading-1">
              <FormattedMessage id="process" />
            </h3>
            <div className="selection-process-illustration">
              <div className="illustration-big-screen">
                <div className="illustration-icons">
                  {
                    rndSelectionSteps.map(step => (
                      <img
                        src={step.img}
                        alt={step.alt}
                        className="step-icon"
                        key={step.name}
                      />
                    ))
                  }
                </div>
                <div className="illustration-circles">
                  {
                    rndSelectionSteps.map((step, i) => {
                      const isLast = i === rndSelectionSteps.length - 1;
                      const isFirst = i === 0;
                      return (
                        <div key={`${i}${step.name}`}>
                          {
                            !isFirst && <hr/>
                          }
                          <img
                            src={"/circleIcon.svg"}
                            alt="Circle"
                            className="circle"
                          />
                          {
                            !isLast && <hr/>
                          }
                        </div>
                      )
                    })
                  }
                </div>
                <div className="illustration-labels">
                  {
                    rndSelectionSteps.map((step, i) => (
                      <div key={`${i}${step.name}`}>
                        <h4 className="body-lead">
                          <FormattedMessage
                            id={step.name}
                          />
                        </h4>
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="illustration-mobile">
                {
                  rndSelectionSteps.map((step, i) => (
                    <div key={`${i}${step.name}`}>
                      <div className="process-step-number">
                        {`${i + 1}.`}
                      </div>
                      <h4>
                        <FormattedMessage
                          id={step.name}
                        />
                      </h4>
                      <hr />
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </section>

        <section className="form">
          <h3 className="heading-1 form-title"><FormattedMessage id='r&d_form_title'/></h3>
          <h2 className="heading-3 form-subtitle"><FormattedMessage id='r&d_form_subtitle'/></h2>
          {/*<a className="form-cta" href={`#${createId(openRnDPositions[0].name)}`}><FormattedMessage id='r&d_form_button'/></a>*/}
        </section>
      </div>
    </div>
    )
}

export default RDSection;
