import React from 'react';
import PropTypes from 'prop-types';

const Arrow = ({ isRight, onClick }) => {
  if (isRight) {
    return (
      <svg className="arrow arrow-right" width="116" height="55" viewBox="0 0 116 55" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        <path d="M87.6528 0C87.6528 27.5 115.153 27.5 115.153 27.5C115.153 27.5 87.6528 27.5 87.6528 55" stroke="white"/>
        <line x1="115.153" y1="27.502" x2="-4.37114e-08" y2="27.5019" stroke="white"/>
      </svg>
    );
  }
  return (
    <svg className="arrow arrow-left" width="117" height="55" viewBox="0 0 117 55" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path d="M28 0C28 27.5 0.500001 27.5 0.500001 27.5C0.500001 27.5 28 27.5 28 55" stroke="white"/>
      <line x1="116.153" y1="27.502" x2="1" y2="27.5019" stroke="white"/>
    </svg>
  );
}

Arrow.defaultProps = {
  isRight: false,
  onClick: () => console.log('Clicked.')
}

Arrow.propTypes = {
  isRight: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Arrow;