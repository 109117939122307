import React  from 'react';
import PropTypes from 'prop-types';

import { ViewportProvider } from '../../../services/hooks'

import Header from '../PageLayout/Header/Header';
import Seo from '../../Seo/Seo';
import Footer from '../PageLayout/Footer/Footer';

import './R&DLayout.scss';

const RnDLayout = ({ children, title, description, currentLang, locationProps, navigate }) => {
    const { pathname } = locationProps;
    return (
        <ViewportProvider>
                <Seo title={title} lang={currentLang} description={description} pathname={pathname}/>
                <main className="main-content rnd">
                    <Header locationProps={locationProps} navigate={navigate} />
                    <div className="main-wrapper">{children}</div>
                </main>
                <Footer locationProps={locationProps} navigate={navigate} />
        </ViewportProvider>
    );
}

RnDLayout.defaultProps = {
    title: '',
    description: '',
    currentLang: 'en',
}

RnDLayout.propTypes = {
    children: PropTypes.node.isRequired,
    locationProps: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    currentLang: PropTypes.string,
};

export default RnDLayout;
