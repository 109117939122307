import React from 'react';
import SimpleLocalize from "../components/SimpleLocalize/SimpleLocalize";
import RnDSection from "../components/Sections/R&DSection/R&DSection";
import RnDLayout from '../components/Layouts/R&DLayout/R&DLayout';

const TeamPage = (props) => {
    return (
        <>
            <SimpleLocalize {...props}>
                <RnDLayout
                    hideContact
                    currentLang={props.pageContext.language}
                    title={props.pageContext.messages['r&d']}
                    description={props.pageContext.messages['r&d_desc']}
                    locationProps={props.location}
                    navigate={props.navigate}
                >
                    <RnDSection />
                </RnDLayout>
            </SimpleLocalize>
        </>
    );
}

export default TeamPage;
